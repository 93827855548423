import React from "react"
import {graphql} from "gatsby"
import Seo from "../components/seo";
import Layout from "../components/layout";
import Sidebar from "../components/sidebar";

const WpPost = ({data}) => {
    const {
        wpPost: {title, content, id, categories, excerpt},
    } = data

    return (
        <Layout>
            <Seo title={title} description={excerpt}/>
            <div className="flex flex-wrap">
                <div className="w-full md:w-9/12 px-8 postData">
                    <div className={`post-${id}`}>
                        <h2 className="item-title">{title}</h2>
                        <div className="py-3" dangerouslySetInnerHTML={{__html: content}}/>
                    </div>
                </div>
                <div className="w-full md:w-3/12">
                    <Sidebar title={categories.nodes[0].name} items={categories.nodes[0].posts.nodes} id={id}/>
                </div>
            </div>
        </Layout>
    )
}

export const query = graphql`
  query ($id: String) {
    wpPost(id: { eq: $id }) {
      id
      excerpt
      title
      content
      categories {
        nodes {
          name
          posts {
            nodes {
              id
              title
              slug
              excerpt
            }
          }
        }
      }
    }
  }
`

export default WpPost
