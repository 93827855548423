import React, {useEffect} from "react"
import * as styles from "./sidebar.module.css"
import {loadCaptchaEnginge, LoadCanvasTemplate, validateCaptcha} from 'react-simple-captcha';
import {useForm} from "react-hook-form";

const ContactForm = () => {
    const {register, handleSubmit, reset, formState: {errors}} = useForm();

    useEffect(() => {
        loadCaptchaEnginge(6);
    }, []);

    const onSubmit = data => {
        let user_captcha_value = document.getElementById('user_captcha_input').value;
        if (!validateCaptcha(user_captcha_value) === true) {
            alert('Captcha Does Not Match');
            return
        }

        let formdata = new FormData();
        formdata.append("your-name", data.name);
        formdata.append("your-email", data.email);
        formdata.append("your-message", data.message);
        const requestOptions = {
            method: 'POST',
            body: formdata,
        };

        fetch(`${process.env.GATSBY_API_URL}wp-json/contact-form-7/v1/contact-forms/694/feedback`, requestOptions)
            .then(response => response.json())
            .then(() => {
                alert('Form submitted successfully.')
                reset();
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    return (
        <>
            <h3 className={styles.sidebarTitle}>Contact Us</h3>
            <div className="py-2">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="grid gap-4 grid-cols-2">
                        <div className="col-span-12">
                            <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                                Your name*
                            </label>
                            <input
                                type="text"
                                {...register("name", {required: true})}
                                name="name"
                                id="name"
                                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            />
                            {errors.name?.type === 'required' &&
                                <span className="error text-red">Name is required</span>}
                        </div>
                        <div className="col-span-12">
                            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                Your Email*
                            </label>
                            <input
                                type="email"
                                {...register("email", {required: true})}
                                name="email"
                                id="email"
                                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            />
                            {errors.email?.type === 'required' &&
                                <span className="error text-red">Email is required</span>}
                        </div>
                        <div className="col-span-12">
                            <label htmlFor="message" className="block text-sm font-medium text-gray-700">
                                Message*
                            </label>
                            <textarea
                                name="message"
                                {...register("message", {required: true})}
                                id="message"
                                rows={4}
                                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            />
                            {errors.message?.type === 'required' &&
                                <span className="error text-red">Message is required</span>}
                        </div>
                        <div className="col-span-12">
                        <label htmlFor="workPhoneNumber" className="block text-sm font-medium text-gray-700">
                            Enter text in field below for validation.
                        </label>
                        <LoadCanvasTemplate/>
                        <input
                            type="text"
                            name="captcha"
                            {...register("captcha", {required: true})}
                            id="user_captcha_input"
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        />
                        {errors.captcha?.type === 'required' &&
                            <span className="error text-red">Captcha is required</span>}
                    </div>
                        <div className="py-3 bg-gray-50 text-right">
                            <button
                                type="submit"
                                className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            >
                                Send
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
}

export default ContactForm