import React from "react"
import * as styles from "./sidebar.module.css"
import {getImage} from "gatsby-plugin-image";
import {convertToBgImage} from "gbimage-bridge";
import BackgroundImage from 'gatsby-background-image'
import {graphql, Link, useStaticQuery} from "gatsby";
import ContactForm from "./contactForm";

const Sidebar = ({title, items, id}) => {
    const {backgroundImage} = useStaticQuery(
        graphql`
      query {
        backgroundImage: file(relativePath: { eq: "mod-rt-bg.png" }) {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        },
      }
    `
    )

    const image = getImage(backgroundImage)
    const bgImage = convertToBgImage(image)

    return (
        <BackgroundImage
            Tag="div"
            {...bgImage}
            className="float-left"
            style={{
                // Defaults are overwrite-able by setting one or each of the following:
                backgroundSize: 'auto',
                backgroundPosition: '0 100%',
                backgroundRepeat: 'no-repeat',
            }}
        >
            <div className="px-6">
                {id === 'cG9zdDoxNTE=' && <ContactForm/>}
                <h3 className={styles.sidebarTitle}>
                    {title}
                </h3>
                <div className="py-2">
                    <ul>
                        {items && items.map(item =>
                            <li className={`py-1 ${styles.itemBorder}`} key={item.id}>
                                <Link to={`/${item.slug}`} key={item.id}>
                                    {item.title}
                                </Link>
                            </li>
                        )}
                    </ul>
                </div>
            </div>
        </BackgroundImage>
    )
}

export default Sidebar
